import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Prerequisites`}</h2>
    <ul>
      <li parentName="ul">{`A user with access to Engagement Studio.`}</li>
    </ul>
    <h2>{`Overview`}</h2>
    <p><strong parentName="p">{`Onsite campaigns`}</strong>{` are targeted marketing efforts executed directly on a website, offering real-time engagement and personalized messaging. They can take various forms, such as pop-ups, banners, or embedded widgets, and are designed to engage visitors in real-time. They boost conversions by presenting relevant offers to users based on their behavior and preferences, enhancing the customer experience. These campaigns are cost-effective, flexible, and provide valuable data insights, making them a powerful tool for increasing user retention and building brand loyalty.`}</p>
    <h2>{`Steps`}</h2>
    <h3>{`1. Add campaign`}</h3>
    <ol>
      <li parentName="ol">{`On the side bar of Engagement Studio, click `}<em parentName="li">{`AdCenter`}</em>{` (`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "67px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "104.4776119402985%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAABYlAAAWJQFJUiTwAAACc0lEQVQ4y41V2XaiQBDNB8w5zmgii4iC24hZVOK+4BKDsiXRZP7/T+6cKoGgiZqHC0139a2qvl3FlZjTcAqCXDwzV/x2z9WpjUmCG1FlCPJl51fnyCKUqwZKVQM/ySYmvBbyyGTz/E5ClIuwZgsGpXm8nskqHH1EyilnpQLuH0wsFitYsyWDxt3eCP3hBIPhBG2zh9HYQqc7xDxhN188oVa/Zw6OkNhLFQMvr2+8qdXuMtpml50MR1OsVjam1gLjiYVmq4OW2WXQuNcfwQ9eIef1fYQUtnHbxOrZRiotxmkQorS00l8U9Cr+XMvxfEb4tHFdn9cpSiasNx7wbK9xI+YhKXp80Dm1BFnR+YzUYhXGbQt6uR6nR6B11/OhapVPQiMkvBaU2JDmN47HKf/OSNg4Lm/0/YDTjISQctpXwnqCUAojonPdbndwXA+iXGDyUqWOXF5H8PLK62QnKz8gTGdzrJ7jeHjb7nD3YGL59IxytY5fqSzstQPjrsnX7CIhKVXQa9judphac9j2huF6AczHHvqDMTw/iFWVLhHSZKXWQPuxx6oKUgGd7gDDsYX1xsVsvkRRr8XCXDjDvcrkmRZpTKAxQ1TZZh+ZHqqs8bU5UrnJaZGaB2UlKBz1cbkdl53nBSho4T2kB334wQunM5nOMJnOw/cpzBlUOU8rG+u1w3V+UMt0JaicqMR2u3e02h2YnT7Mx0PQ2dKZfnz8Y4GoTNUwuoNuQxOpjIRytcFeo/TpahyChFM5KlI6fZOLW1ccYbInyvkSezzX++iqkE2yTON+uM9dO/AShX/cfI9tkt/7bh5G+N0/5GsnL551Eq3/B45MXNHI/hT6AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/381f8/ec-adCenter.webp 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
                "alt": "icon-adCenter",
                "title": "icon-adCenter",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`).`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d97bc1c5e7e45691e309288d886aeeda/d0f99/ec-menu.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAACe0lEQVQoz32QyWsTARTG5z8QVFwuKiq2dBFJBdGjqOCl3hREcVf8B8STUMW9HkTQghdXaOtS28nMtLbBYsFaWxubGmNrYpqETDJJprOkmWZp8pOmKnjxgx+893jv8fEJdTt2s+HiS7bdGKDpZh9bLktsbhGpvSJSd1Wk4Zqbhusi9ddFXLclXK0S2+9INLW62XVXYe+Dt+xr62fPPTc773QhrGvcxS3ZSyht8zVuEEhafP+DZhFM24xGMyj+GAFVxx/TCGqz9AbijM5oTMZ1ApqJTzU40T6EsGqLizdjQf6nChDVbaiUoVyASpGE7WBlc5QKhSrlUpEL0keENTVNdI5MVQ8LpQUWypV/WNRcvkh48SFQWhoR1i0iagJd19ENA9OyuCAPI6yucfHy03R1qbiwQPn3k6qzylLtFEtEZrOUi3mcuSyVYp64mcU0DBzLpJjLUcnPc2lgBGFtrYuWnhEmEyYjkTRedRbJH+V9UGU0msKr6nh+xOmbiqJZNtNahphhI09FaPs4waOxSR6P+3ni9XO2x4OwbH094pcQ0Xia+52DBEIqUTOH5cxjW1aVfKnE0E+N3m9J/vgfjiXY/7yb5nY3zR0iBzpEDr6SEFZu2srToUmeicMktRQPXwwyEUlhOw4z4TChUAgzm8UXzzAejP/NdTyhcei1zNFuhWM9CsfERWSE5Rsb6Rqbpve9j/4PX3n1doxwxmK+UEBPpchoGk6+QEw3sNPa33x9qTSHuyWOSwonFYVTvQqn+xSEFRsbafN4+RaM0OH5TCxt4tcMfDMqM2qSTHaORDbHeHSxT6BaNrM5h3fhCEd63JyUZc70yZzrlznvkfkF/n6e7vxCZiUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d97bc1c5e7e45691e309288d886aeeda/e93cc/ec-menu.webp 300w", "/static/d97bc1c5e7e45691e309288d886aeeda/b0544/ec-menu.webp 600w", "/static/d97bc1c5e7e45691e309288d886aeeda/68fc1/ec-menu.webp 1200w", "/static/d97bc1c5e7e45691e309288d886aeeda/a2303/ec-menu.webp 1800w", "/static/d97bc1c5e7e45691e309288d886aeeda/4293a/ec-menu.webp 2400w", "/static/d97bc1c5e7e45691e309288d886aeeda/81194/ec-menu.webp 3014w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d97bc1c5e7e45691e309288d886aeeda/eed55/ec-menu.png 300w", "/static/d97bc1c5e7e45691e309288d886aeeda/7491f/ec-menu.png 600w", "/static/d97bc1c5e7e45691e309288d886aeeda/8537d/ec-menu.png 1200w", "/static/d97bc1c5e7e45691e309288d886aeeda/d2cc9/ec-menu.png 1800w", "/static/d97bc1c5e7e45691e309288d886aeeda/00711/ec-menu.png 2400w", "/static/d97bc1c5e7e45691e309288d886aeeda/d0f99/ec-menu.png 3014w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d97bc1c5e7e45691e309288d886aeeda/8537d/ec-menu.png",
              "alt": "ec-menu",
              "title": "ec-menu",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
2. Click `}<em parentName="p">{`Campaigns`}</em>{` on the menu.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1142px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9d58220d59f992bf224028c42f529ca9/006e5/ec-adCenter-campaign.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "140.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAYAAABh2p9gAAAACXBIWXMAABYlAAAWJQFJUiTwAAAEkklEQVRIx5XU6VNTZxTH8audVm3dkF2BmJBAglaxiw61HWfU1mlRrI4O1g3BIkIIEdkklkIJBWuxikAFlTUs2ZO7JAS3mfqmf9a3c28wgnUUXpx5Xj2fOef5nXuFzU0S66+LrG8Q+eS6yMYGkaQmiaRGkc2OJ2T+1Ik5z4xl916Mpc2YLvdgKndiKu/CcMmJsfx15V92Iqy2BTG0z6Nrm8PQHiO5RUGo9iNU+xDsEYQjjVirrlK49xs2fHuFz6pvUVDxG3mXOiisdPJ5VRe7KjvZU9WFpaIjDqbeUEhpUbRzQ4OEYA2wyupHqI/yUXELJT8cxbzrS1KPWsm/3MX2823knG8jr7wDY1m7dporOtBf/BVBu1wbYHVtAEFFavwaKNT4EK4prDl6g6SNSaRs1ZNWYsNU4eSr2h6KrD0U1S6UtZui2m7223oQVtcGyWxVyPklSkqzjHDVF0dVsE5h/Yk2vjt0mH1fHyS5uIbu4SmUcBCf34/H7SYQ8CPLMrIkIUmSCgZIbpZJbpLY3CiR1iJrwWigPQ5a8szkWgrZ+qON/kkvc7JEKBwmFAoRDoc1SFoMblnANjWIpN9Q2NQoLoSisLakFZPewDa9WQP7JjzEIjJhUUwgoigmSgPTWhQyWhUN08at9ic6XFviQJ+jI8tgIeuEjfuTHmJKHHyFvEKXjKzu3ge2oBZQIhS7wsfHb5Kfa0Rn2kH2yaXg4lFfj6yuTYvClib5dcKJlCOsK3Gwq6AAff6nGqi94btAtatXKWtvt3ht7IoGGnTbyc61oDtVR/+k592gOqKa6hp7aGEXl4687riDPEN8ZA10LaPDrJsRPqwL/n9kNZRjrRqYYyxg++k67Q2jikwoLC5JN5Fy/PIi6M0OSxwUqHto3q2BQzM+ns1FiMiyBr9ZglAb5K2lfobXoqw51oouK5tt+nx0p204BsZxeXyMzHgYnfUuqbFZ70KHby1/IuWdZgumgkINPNB4mzJnHxc6+7jojNeFzntcdN6jrKvvfR1GtMXemp5JRo6J3NI67ozNIIcCeL1evB4vPq+XWFTh6fyc9j0vC8xIzSA924jprJ3BKTVliWAoxHwsij8Q5NbdIe70P4qnvBwwMy3eoQoOTHmIyBKxuSjtt/rR7z+L5WAZeQcuUHCobPlgeraJvLN2+ifdvHgyx1+DI6QUnqSpvVf76/h8Aa40OFcG5qugy80/z+c5fMbOqcpWomKAkcePGR8bxeueWcHIKnjOzoDLw4v5OfZ8/zMO512iioTL5WJmehqfz79yUO3w5fN5jpyr51hZI/++fEEoGEQWw1rSKwLNCx0+i0UZfDhOyp6T2rsFAkFm3T5KqxzvX+zFKavg39Pxv83TJ/P0Djxmx+FycorOkLWvlC+KK98B1rwZipGdZfV0PxhnaGiE4eEhXBNjjI2OMPRogtGJGWLRyMr20HzeTufDKUYnZxl4MMTg8EOmZt2IsoQoy/iCIbVDP8JVN0LVLELVTKJWXZlGsAZZV9xERmo6Gdm5GE5XUdzyO829/TT0DtB4Z4Drf97n2h/3qFfrdh//AZ3y+NrIMEgYAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9d58220d59f992bf224028c42f529ca9/e93cc/ec-adCenter-campaign.webp 300w", "/static/9d58220d59f992bf224028c42f529ca9/b0544/ec-adCenter-campaign.webp 600w", "/static/9d58220d59f992bf224028c42f529ca9/7c99d/ec-adCenter-campaign.webp 1142w"],
              "sizes": "(max-width: 1142px) 100vw, 1142px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9d58220d59f992bf224028c42f529ca9/eed55/ec-adCenter-campaign.png 300w", "/static/9d58220d59f992bf224028c42f529ca9/7491f/ec-adCenter-campaign.png 600w", "/static/9d58220d59f992bf224028c42f529ca9/006e5/ec-adCenter-campaign.png 1142w"],
              "sizes": "(max-width: 1142px) 100vw, 1142px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9d58220d59f992bf224028c42f529ca9/006e5/ec-adCenter-campaign.png",
              "alt": "ec-adCenter-campaign",
              "title": "ec-adCenter-campaign",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
3. Click `}<em parentName="p">{`Add`}</em>{` to open a new Campaign.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9e640e031871779b1f6dd619d333233f/bb056/ec-add-campaign.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAACS0lEQVQoz4XR/UtTURzH8fsfRCgVKj1rkoIapgkRkihYQRAEQQRBIBb1oz2g9OAPItkDVNSydG5T0PSuvE7cZv1gqTnnSgnWNTcL+ik15ja33T3cvcOraEHRB158z4Fzzg+fI+SUlLO5pof0un7SayU21IhsvCySelVk0zWRLbUiaXUiGddFtt4ws+2WmR31ZnbWm8lu6KWgqY/Ce/3k3+6luLEbIW1vMdWmEUyOWXRDMs3DX2h570E/5qVtzIvB4cUw7sW4xoPJ6aFjwkv7+AydLi9dH79invrGJXEUITWzgG7HDCtR+W8SsbVlXAkTCYchmdT2XZNehIzcA4jO1QeTidWjyX9LxLWpxhPaTESjxBRFu9Uz5UHI2l9KVfs7dCMzPBxy82RYRjci83RU5tl7medjMi0OmdbxafTOZTIG17TG6JIxfZimzfUZvdPNFesowvbcIrJudpPdIJHT2Etek8S+uxKF9yWKHkiUPJI4+FjikE6itLmPshYL5a0WKvR9VBr7OWKyaI62rxBSM/MxjLgJRRTm/Ev4QhGNPxIloMTWBKMx/BGFhcASi+EIfiXKD98i8/4AixGFeELFOOlGSNmVh3litUM18Vtff48aj4OqoiZUrb9QSCEaXfkoq2cWIWV3Hq1vPzHv8/N9wcdcIPSH+eC6hWVL636Gwpq5YJBgKESX242QtqeA4juvOKwbpEw3QEXzAJUtVo61WTlutHGiw8bJThunXtg4Ldo489LOWcnOOYudqgE71TY7FwbtXHxj5/xrG78A0wOGSy6/ESYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9e640e031871779b1f6dd619d333233f/e93cc/ec-add-campaign.webp 300w", "/static/9e640e031871779b1f6dd619d333233f/b0544/ec-add-campaign.webp 600w", "/static/9e640e031871779b1f6dd619d333233f/68fc1/ec-add-campaign.webp 1200w", "/static/9e640e031871779b1f6dd619d333233f/a2303/ec-add-campaign.webp 1800w", "/static/9e640e031871779b1f6dd619d333233f/4293a/ec-add-campaign.webp 2400w", "/static/9e640e031871779b1f6dd619d333233f/cb4c3/ec-add-campaign.webp 2962w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9e640e031871779b1f6dd619d333233f/eed55/ec-add-campaign.png 300w", "/static/9e640e031871779b1f6dd619d333233f/7491f/ec-add-campaign.png 600w", "/static/9e640e031871779b1f6dd619d333233f/8537d/ec-add-campaign.png 1200w", "/static/9e640e031871779b1f6dd619d333233f/d2cc9/ec-add-campaign.png 1800w", "/static/9e640e031871779b1f6dd619d333233f/00711/ec-add-campaign.png 2400w", "/static/9e640e031871779b1f6dd619d333233f/bb056/ec-add-campaign.png 2962w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9e640e031871779b1f6dd619d333233f/8537d/ec-add-campaign.png",
              "alt": "ec-add-campaign",
              "title": "ec-add-campaign",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
4. Fill in the name and select `}<em parentName="p">{`Onsite`}</em>{` campaign.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/aa72e7e07448e12adbe4ed4d9d1a0072/ebd60/ec-onsite-new-filled-in.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABbUlEQVQoz52Sz0sCQRTH97/t3rFz1yIqoVIhhegUGJZoEERQnTrUqcAfmeFSaunuzuz83G/MrOumKx188GHm7Xz3+94Mz1lb30C+fIr9fAm5YjmmUMpS/IdCCYfHJ9jc2oVTqTVgQgphEZxh1RiORnAuajWbUBqCMQ7GGKSUEEKAcYEg5CCMI+QyAxMKUimrN9HrfcBpNOIOtdazSlEUwZtM8O0F6H8O8eMThEKDcgUqDPE+CAV0FFm9Cdd1U0OllD1IDk2nBkKo/UkpDaVTpNIIAmL1STP9fn+5oRGYKyt7HWHzaIFEkzGs1+tzhsn1Pc+zHfq+b3Oto+maQinNGl4t6dA8ssEIzfdFowTOuS0kpEoNK9XLeGymBiYIIRiPx2Cc2719vxlJAQVKydzYuMbwrFpbaea4AmQEtEchdm5aOHjo4bn5DmfvqIivwQCtdgedbjfmbUr3z7rAS7NjuXt6xfb5PXL1R1Svb/EL6GY5MYQ9YnwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/aa72e7e07448e12adbe4ed4d9d1a0072/e93cc/ec-onsite-new-filled-in.webp 300w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/b0544/ec-onsite-new-filled-in.webp 600w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/68fc1/ec-onsite-new-filled-in.webp 1200w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/480e2/ec-onsite-new-filled-in.webp 1322w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/aa72e7e07448e12adbe4ed4d9d1a0072/eed55/ec-onsite-new-filled-in.png 300w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/7491f/ec-onsite-new-filled-in.png 600w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/8537d/ec-onsite-new-filled-in.png 1200w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/ebd60/ec-onsite-new-filled-in.png 1322w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/aa72e7e07448e12adbe4ed4d9d1a0072/8537d/ec-onsite-new-filled-in.png",
              "alt": "ec-onsite-new-filled-in",
              "title": "ec-onsite-new-filled-in",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Name`}</em>{`: It is required  and it is free text but you should give a meaningful name.`}</li>
      <li parentName="ul"><em parentName="li">{`Type`}</em>{`: You can select `}<em parentName="li">{`Onsite`}</em>{` or `}<em parentName="li">{`Landing page`}</em>{` or `}<em parentName="li">{`A/B testing`}</em>{` or `}<em parentName="li">{`Offsite`}</em>{`. This field defines the type of campaign.`}</li>
      <li parentName="ul"><em parentName="li">{`Channel`}</em>{`: You can select `}<em parentName="li">{`Default`}</em>{` or create a new one to add.`}</li>
      <li parentName="ul"><em parentName="li">{`Contact list`}</em>{`: You can choose an existing contact list, but it's not mandatory.`}</li>
    </ul>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`On the right bottom corner of form `}<em parentName="li">{`New campaign`}</em>{`, click button `}<em parentName="li">{`Accept`}</em>{`.`}</li>
    </ol>
    <h4>{`Result`}</h4>
    <p>{`After accepting, `}<em parentName="p">{`Edit campaign`}</em>{` is open.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9b584ae2fed6a3a938560c4c94f43bc3/3cc45/ec-edit-campaign-onsite.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABq0lEQVQoz42SSW/TQBiG8zuQkBBiEUUgLlzhDj+JRVS0UITghMQB8R9wCoFeuPTWLCVBXZBQixMrcb20KPYkXmbmQV5CEhSgr/TIM+OZ93tnqSytfOLico0z9wzOPjA499Dg/CODC8sGlx5XubxS5cpqlaUnVa4+rXJtbZ3rz9a58fw9N1/UuP1qgztvPnP3dY1bL99R6XQ9tg496qZP3fRomB7Nrk+rd0yzl32nbGdYUxqmQ9u0ebu5z+rGLvc/bFPhb9KS0yocJwSRZPPALgxTqVBa52SKooggFEg1Hc+QSqNmmVmTqWm5heE4kUSJJE5V3j4WMSJOiVOZU/yToDV6AVnhTA3LKQwTqYrqZYpRlBAlCamUcyilFhpOUrb6pWFRQecLsu2OhCBO0nwyJfofqDLhl0FpmJYDUkqEEAyHwzyl/o/Rnwnb9tyWFaEQBGHIMAgQUfz7GGYvYFF/Eqhz5Mw/G50mJONRjpanfzYTffN9KjuDE+pdl5bl0u77NHsOWz/6tKwj2n2XzsClY7t8tbO2w47jsesW7Hke+77Hnu9x8POEj4ff+QWSoTYx3ylK1AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9b584ae2fed6a3a938560c4c94f43bc3/e93cc/ec-edit-campaign-onsite.webp 300w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/b0544/ec-edit-campaign-onsite.webp 600w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/68fc1/ec-edit-campaign-onsite.webp 1200w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/a2303/ec-edit-campaign-onsite.webp 1800w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/4293a/ec-edit-campaign-onsite.webp 2400w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/b41d4/ec-edit-campaign-onsite.webp 2850w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9b584ae2fed6a3a938560c4c94f43bc3/eed55/ec-edit-campaign-onsite.png 300w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/7491f/ec-edit-campaign-onsite.png 600w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/8537d/ec-edit-campaign-onsite.png 1200w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/d2cc9/ec-edit-campaign-onsite.png 1800w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/00711/ec-edit-campaign-onsite.png 2400w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/3cc45/ec-edit-campaign-onsite.png 2850w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9b584ae2fed6a3a938560c4c94f43bc3/8537d/ec-edit-campaign-onsite.png",
              "alt": "ec-edit-campaign-onsite",
              "title": "ec-edit-campaign-onsite",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`The first three tabs on onsite campaigns are about the elements created in the campaign `}<em parentName="p">{`Passive elements`}</em>{`, `}<em parentName="p">{`Inline elements`}</em>{` and `}<em parentName="p">{`Active elements`}</em>{`. `}<br />{`
The configuration of passive and active elements are similar and the options for the type of element are the same. `}</p>
    </blockquote>
    <h3>{`2. Add a element`}</h3>
    <ol>
      <li parentName="ol">{`Go to tab `}<em parentName="li">{`Active elements`}</em>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9c2aeb7758f898652d83b7ec4bab16bf/7cd4f/ec-edit-campaign-active-element.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAxklEQVQY043PTU8DIRSFYf7/1oW/xo1uXbpq1OlHTGPoTGcGWhi4l9eANbHqwps8gRzCSa557Cwv7yPdwbHufbPpPdvh1GyGev9td/R0h4m3fuTuacvN/TO3DytMWDJ/jmZQ4T+TUsZHYT04zBRSC0ULWgqlgKoyO09YUstUL8q1Un0rtj5gjqeFJMqSlZgE6yIupJbFrFdvtYBaUS7qIiLknCki7CePsS4gWj9JE5IQs7SiJF/np/xD3cqfz4zzjHOOV9vzAcOGhJQZZvrJAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9c2aeb7758f898652d83b7ec4bab16bf/e93cc/ec-edit-campaign-active-element.webp 300w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/b0544/ec-edit-campaign-active-element.webp 600w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/68fc1/ec-edit-campaign-active-element.webp 1200w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/a2303/ec-edit-campaign-active-element.webp 1800w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/4293a/ec-edit-campaign-active-element.webp 2400w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/6dd60/ec-edit-campaign-active-element.webp 2802w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9c2aeb7758f898652d83b7ec4bab16bf/eed55/ec-edit-campaign-active-element.png 300w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/7491f/ec-edit-campaign-active-element.png 600w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/8537d/ec-edit-campaign-active-element.png 1200w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/d2cc9/ec-edit-campaign-active-element.png 1800w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/00711/ec-edit-campaign-active-element.png 2400w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/7cd4f/ec-edit-campaign-active-element.png 2802w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9c2aeb7758f898652d83b7ec4bab16bf/8537d/ec-edit-campaign-active-element.png",
              "alt": "ec-edit-campaign-active-element",
              "title": "ec-edit-campaign-active-element",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
2. Click `}<em parentName="p">{`Add`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0e58afa7c509a2d30d91c8c950fddccb/1c412/ec-add-element.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABPklEQVQoz6VSPU/DMBDNj0diYIaJASSGsiIEiNKk0CpdMiCFDkCRGBjYqBJ/np08dG4NaQtSBSc93enZ7/nsc7J/eIx0lOPyJsPVYIjr9A79bIR+FvMSw+2QPD49g8MYDbIG/42kLB9CQeTgvYfzHlLKAGstpFKQUoGI4JxbgTYmZNZFbTKdToNh0zQht20LIUSAJUJdL2oW8hrvY3DNh7JR1IUOu4ZMxgXuqHtINOoi7u9io8NYz+cfEMqAnEf7i0HkVt5wvcMYZC2EJlj/za0b/sRvdKiUQlVVi3cjghQCdV0Hfv3qWmt438A3LTxzbFiWZTCK02IhG7ChMeZrQMyHSS4nzLUOnOtcuEUym738+c+xFf/cg/wNu+kr7t81kl6vh6IokOc5JpPJ1hiNcwxuxwF7J+fYOTrD6UWKT1TcQp2fCHRuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0e58afa7c509a2d30d91c8c950fddccb/e93cc/ec-add-element.webp 300w", "/static/0e58afa7c509a2d30d91c8c950fddccb/b0544/ec-add-element.webp 600w", "/static/0e58afa7c509a2d30d91c8c950fddccb/68fc1/ec-add-element.webp 1200w", "/static/0e58afa7c509a2d30d91c8c950fddccb/61c51/ec-add-element.webp 1276w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0e58afa7c509a2d30d91c8c950fddccb/eed55/ec-add-element.png 300w", "/static/0e58afa7c509a2d30d91c8c950fddccb/7491f/ec-add-element.png 600w", "/static/0e58afa7c509a2d30d91c8c950fddccb/8537d/ec-add-element.png 1200w", "/static/0e58afa7c509a2d30d91c8c950fddccb/1c412/ec-add-element.png 1276w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0e58afa7c509a2d30d91c8c950fddccb/8537d/ec-add-element.png",
              "alt": "ec-add-element",
              "title": "ec-add-element",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
3. On the right bottom corner of form `}<em parentName="p">{`Add element`}</em>{`, toogle on and click button `}<em parentName="p">{`Accept`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/729a5beaf2ff17128d9cb57da10ab1c2/b85b2/ec-add-element-filled-in.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABZklEQVQoz6WSzUpCQRTHfaseIWjTok2QoBX0CkGroE1fEFIKPoWLgoIoLFMrct/CtkEqKfdjvuf+49x7R69aC+nAn5k5c85vzsyZXPu1g9t6A3ePT7hvtPDQfP5drRmlvnqzjebLG2pXN1haXkUOADhn0EriPxYEAVbW1hOgUgrGWFhrwRiD7/sQQiAMwzhQSgWtDbTWYzHGoU3iI+v1etgsFhOgMQZRFMUbBCQIHcI5j+eURPt0oBPdyvnIBoMBCoXCNNBBac2lgh8KSB1BaAuuLISajEJHsJSTARazFZI54Gg4RH/k42sYpMkJYFp2qog5oNsgoysKpaEnrnFyFjJ75bkKqRGe56XvpsAZi9f0rpRIcW6khv1ZoXt0KWUcSHJNoTkdlG0IAYWUMOk86XJ/AlzUFP0GC3S/JXYv37F33UXn4xM721vIVatVVCqVhVQuJ2Pp/AL7R2c4OC3h8PgE+fwGfgC5MChN2qfaWQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/729a5beaf2ff17128d9cb57da10ab1c2/e93cc/ec-add-element-filled-in.webp 300w", "/static/729a5beaf2ff17128d9cb57da10ab1c2/b0544/ec-add-element-filled-in.webp 600w", "/static/729a5beaf2ff17128d9cb57da10ab1c2/68fc1/ec-add-element-filled-in.webp 1200w", "/static/729a5beaf2ff17128d9cb57da10ab1c2/ed4c1/ec-add-element-filled-in.webp 1368w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/729a5beaf2ff17128d9cb57da10ab1c2/eed55/ec-add-element-filled-in.png 300w", "/static/729a5beaf2ff17128d9cb57da10ab1c2/7491f/ec-add-element-filled-in.png 600w", "/static/729a5beaf2ff17128d9cb57da10ab1c2/8537d/ec-add-element-filled-in.png 1200w", "/static/729a5beaf2ff17128d9cb57da10ab1c2/b85b2/ec-add-element-filled-in.png 1368w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/729a5beaf2ff17128d9cb57da10ab1c2/8537d/ec-add-element-filled-in.png",
              "alt": "ec-add-element-filled-in",
              "title": "ec-add-element-filled-in",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Element type`}</em>{`: You can select `}<em parentName="li">{`Active element`}</em>{`. `}</li>
      <li parentName="ul"><em parentName="li">{`Name`}</em>{`: Give a meaningful name.`}</li>
      <li parentName="ul"><em parentName="li">{`Status`}</em>{`: A toogle `}<em parentName="li">{`on`}</em>{` or `}<em parentName="li">{`off`}</em>{`.`}</li>
      <li parentName="ul"><em parentName="li">{`Active placeholder`}</em>{`: You can select `}<em parentName="li">{`-new placeholder-`}</em>{`, if you want to create a new one or re-use an existing. `}
        <blockquote parentName="li">
          <p parentName="blockquote">{`Check `}<a parentName="p" {...{
              "href": "/configure-placeholder.md"
            }}><em parentName="a">{`Configure-placeholder`}</em></a>{` for additional information.`}</p>
        </blockquote>
      </li>
    </ul>
    <h4>{`Result`}</h4>
    <p>{`After accepting, the element is added to the campaign.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cca475ab49a5b34a3ee7fcdf6c76b168/491e4/ec-active-element-survey.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABIklEQVQoz43Ry0vDQBAG8PzdngXBgwfzaBL07t2DFI9eWqxNfLQK2keaNib7iGJqLTa7n+xuQCoeOvBjlhnmu6zVHRW4nnL0EuNmxtFPywbXImX+K270EoJ4kuHk6gn77SH2ziNY+LcEIGvsWtXqG9W6RmdKTGAtBISQkNL4qJZYrr4ghGh2pv+lbmp1B6mDh0VpAoUKBfC5kVjX0OtNM5fNbpd6pO+wOC9RUIacUEwWOWZZAUIZCkq3qBllDIxz8LIEa96zNMUkSUCyDN1xAuvo8AC+YyNwHYSeo7vv2oazreXYCD0Pp0GAsOXBd10982wboX2Ms/YlrM7LXP9WP6VaNKeIF8ZtRnGnvFLcK7nxkFMMCqYNCcOAMIz4Gy6ex/gBhCf92qIt2loAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/cca475ab49a5b34a3ee7fcdf6c76b168/e93cc/ec-active-element-survey.webp 300w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/b0544/ec-active-element-survey.webp 600w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/68fc1/ec-active-element-survey.webp 1200w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/a2303/ec-active-element-survey.webp 1800w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/4293a/ec-active-element-survey.webp 2400w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/3da6b/ec-active-element-survey.webp 2818w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/cca475ab49a5b34a3ee7fcdf6c76b168/eed55/ec-active-element-survey.png 300w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/7491f/ec-active-element-survey.png 600w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/8537d/ec-active-element-survey.png 1200w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/d2cc9/ec-active-element-survey.png 1800w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/00711/ec-active-element-survey.png 2400w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/491e4/ec-active-element-survey.png 2818w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/cca475ab49a5b34a3ee7fcdf6c76b168/8537d/ec-active-element-survey.png",
              "alt": "ec-active-element-survey",
              "title": "ec-active-element-survey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3>{`3. Configure element by adding a widget`}</h3>
    <ol>
      <li parentName="ol">{`On the bottom left of the tab `}<em parentName="li">{`Active elements`}</em>{` in `}<em parentName="li">{`Edit campaign`}</em>{`, click button `}<em parentName="li">{`Add new element`}</em>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cca475ab49a5b34a3ee7fcdf6c76b168/491e4/ec-active-element-survey.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABIklEQVQoz43Ry0vDQBAG8PzdngXBgwfzaBL07t2DFI9eWqxNfLQK2keaNib7iGJqLTa7n+xuQCoeOvBjlhnmu6zVHRW4nnL0EuNmxtFPywbXImX+K270EoJ4kuHk6gn77SH2ziNY+LcEIGvsWtXqG9W6RmdKTGAtBISQkNL4qJZYrr4ghGh2pv+lbmp1B6mDh0VpAoUKBfC5kVjX0OtNM5fNbpd6pO+wOC9RUIacUEwWOWZZAUIZCkq3qBllDIxz8LIEa96zNMUkSUCyDN1xAuvo8AC+YyNwHYSeo7vv2oazreXYCD0Pp0GAsOXBd10982wboX2Ms/YlrM7LXP9WP6VaNKeIF8ZtRnGnvFLcK7nxkFMMCqYNCcOAMIz4Gy6ex/gBhCf92qIt2loAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/cca475ab49a5b34a3ee7fcdf6c76b168/e93cc/ec-active-element-survey.webp 300w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/b0544/ec-active-element-survey.webp 600w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/68fc1/ec-active-element-survey.webp 1200w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/a2303/ec-active-element-survey.webp 1800w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/4293a/ec-active-element-survey.webp 2400w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/3da6b/ec-active-element-survey.webp 2818w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/cca475ab49a5b34a3ee7fcdf6c76b168/eed55/ec-active-element-survey.png 300w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/7491f/ec-active-element-survey.png 600w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/8537d/ec-active-element-survey.png 1200w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/d2cc9/ec-active-element-survey.png 1800w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/00711/ec-active-element-survey.png 2400w", "/static/cca475ab49a5b34a3ee7fcdf6c76b168/491e4/ec-active-element-survey.png 2818w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/cca475ab49a5b34a3ee7fcdf6c76b168/8537d/ec-active-element-survey.png",
              "alt": "ec-active-element-survey",
              "title": "ec-active-element-survey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
2. Scroll down until you find `}<em parentName="p">{`Inline survey`}</em>{` and select.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/386e00f367c3cde77d1d3d66f774a736/1e78a/ec-add-element-list.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAABYlAAAWJQFJUiTwAAACjklEQVQ4y41UCW7bMBDU//9VtEiRxlcdS74l6qao04nvKYbCKobRAiUwIC1yZ2cvO9Pf7zgdjyirCsfTEff7Hbfbze5yfoR8f8T1egXXdu/Dmb179sfpdLIGsoTgfxZJuXZ+AGcxG+OjrXC+XHG7XnA+n63HNE0RRRHath3w+flpnfBecLlcrBjc79hToTt5RRKFWEcaUaZRVZUFyXa7HTabzbDv93sEQQDf9xHHsX1Dx3zfNA3W6zWcxfw3Um2wDjXi3NgLggYkIEiglBoQhiHKsoQxxu51XaPrOuvU8SavSOMQm9ggL/pLEtKQarTWKIrCGggYvjgmaMNvVuHy7TsKtcEqNjCmRNP0hAyHyrhnWWbVEBLePwm90Q8U0R5eZJBog7IiYWuJttutheSOqkku4VK5gIR9yNNfKLMIflJAJTlUom1PUt1iscByuYTneQNc17Xfeead3NNJTzh+gY4DrOM+nK5trDcqpKo8z60qghVNksQWTPIq+eTZhvw2niAsWuyi3Oavqr9yyFBpTBKSibHszB1hhXSdVepM5wso3UKlGqas+hZo+7Zh/zFvrDSVSkGE6JFwKMp47mGvO3hBCk/lUFmBWJcIAjU0NcHiMAUM/W/EXdtiRcLRdIbM1NjGup+W3KB+aBsBySSnz61DwsPhAG+1gjOZzeCnBcbrCCuV2vGrqwpKhZZIJkVIOSVCKu1DNHUNd7mE8zqaIdENNlEObfoq0ytDo7FUVWaX+SSZEEnIH4cDlgz552iOIG+xClNo8zV6JGPemEdpcDmTWJwIKXNoFU7GI2S5hqtyO8vNQ9vQmEplGqiOeG6dvn/bPofjl28okwBBVg5/XXwk/ffcIkLwDPbhu+viDz/XkQRyDGRKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/386e00f367c3cde77d1d3d66f774a736/e93cc/ec-add-element-list.webp 300w", "/static/386e00f367c3cde77d1d3d66f774a736/b0544/ec-add-element-list.webp 600w", "/static/386e00f367c3cde77d1d3d66f774a736/68fc1/ec-add-element-list.webp 1200w", "/static/386e00f367c3cde77d1d3d66f774a736/59a8d/ec-add-element-list.webp 1668w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/386e00f367c3cde77d1d3d66f774a736/eed55/ec-add-element-list.png 300w", "/static/386e00f367c3cde77d1d3d66f774a736/7491f/ec-add-element-list.png 600w", "/static/386e00f367c3cde77d1d3d66f774a736/8537d/ec-add-element-list.png 1200w", "/static/386e00f367c3cde77d1d3d66f774a736/1e78a/ec-add-element-list.png 1668w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/386e00f367c3cde77d1d3d66f774a736/8537d/ec-add-element-list.png",
              "alt": "ec-add-element-list",
              "title": "ec-add-element-list",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
3. Fill in `}<em parentName="p">{`Name`}</em>{`, toggle on and `}<em parentName="p">{`choose survey`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/851f8091a036872ea10e1b93562a97b9/f8b84/ec-configure-element-survey.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABkklEQVQ4y6VTTU8CMRDdH+0PMfGg8SAXPRkPXkg4ECCQ6EFEQOx+iaJyUNhPloXd7e4zU7Z8Bi5M8jLTdvr6ptMqF4VrqIaJp1Ybz+0u2q89MN2EqpvQDBOq8S7GTDPAdGMRb+FN02F+fOK+WIJSbzRAxnkCZBmOsX6/D6VareaEHFmWCVA8CQLYrg/LsuD7PqbTKaIoQpJwJEmyBOWSJ1NVFUqtVhODNE03TvP8Cf68EHEcC8zn850caVlemaZpK4VhGIpNBEkSRfEyJiWkkNbJy5gwm81WhJVKRWyg0hzHwXg8xmAwwNfPEL+Wh1m+wR6NEK2RbZOuKdwtmdQQ5BznKezhEEg5srzEbVBur9fbvEO5KM11PdGM9bvaR7ZsSrlcFoMgCHYuXSo9RLZOyBiDUiwWl00hUlIkfRjzg0QSSX5ot9tddZneGjXFsm3YjgPXdeFO5yKZ7wEpW6hbKNQ1eof1Bo61by8Bs1O0mQHl7PIKTNPzv9wR/3nhD6PV6eCh2cJj8wWnd2WcFEo4v7nFP/4L3jRUfAj+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/851f8091a036872ea10e1b93562a97b9/e93cc/ec-configure-element-survey.webp 300w", "/static/851f8091a036872ea10e1b93562a97b9/b0544/ec-configure-element-survey.webp 600w", "/static/851f8091a036872ea10e1b93562a97b9/68fc1/ec-configure-element-survey.webp 1200w", "/static/851f8091a036872ea10e1b93562a97b9/1c0ca/ec-configure-element-survey.webp 1682w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/851f8091a036872ea10e1b93562a97b9/eed55/ec-configure-element-survey.png 300w", "/static/851f8091a036872ea10e1b93562a97b9/7491f/ec-configure-element-survey.png 600w", "/static/851f8091a036872ea10e1b93562a97b9/8537d/ec-configure-element-survey.png 1200w", "/static/851f8091a036872ea10e1b93562a97b9/f8b84/ec-configure-element-survey.png 1682w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/851f8091a036872ea10e1b93562a97b9/8537d/ec-configure-element-survey.png",
              "alt": "ec-configure-element-survey",
              "title": "ec-configure-element-survey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Name`}</em>{`: It is required and pre-filled with date and time. It is free text but you should give a meaningful name.`}</li>
      <li parentName="ul"><em parentName="li">{`Active`}</em>{`: A toggle `}<em parentName="li">{`on`}</em>{` or `}<em parentName="li">{`off`}</em>{`.`}</li>
      <li parentName="ul"><em parentName="li">{`Choose survey`}</em>{`: Select a survey from the list.`}</li>
      <li parentName="ul"><em parentName="li">{`Override original dimensions`}</em>{`: A toogle `}<em parentName="li">{`on`}</em>{` or `}<em parentName="li">{`off`}</em>{`.`}</li>
      <li parentName="ul"><em parentName="li">{`Country flag`}</em>{`: Toggle for change to the countries availables for customer.`}</li>
      <li parentName="ul"><em parentName="li">{`Window variables`}</em>{`: A key/value fill.`}</li>
      <li parentName="ul"><em parentName="li">{`Custom event code (impression)`}</em>{`: A toogle `}<em parentName="li">{`on`}</em>{` or `}<em parentName="li">{`off`}</em>{`.`}</li>
      <li parentName="ul"><em parentName="li">{`Custom event code (Beggining of the call)`}</em>{`: A toogle `}<em parentName="li">{`on`}</em>{` or `}<em parentName="li">{`off`}</em>{`.`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`Make sure the Survey is already created in `}<em parentName="p">{`AdCenter`}</em>{` > `}<em parentName="p">{`Surveys`}</em>{` > `}<em parentName="p">{`Add`}</em>{` > `}<em parentName="p">{`Create survey`}</em>{`. `}</p>
        </blockquote>
      </li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`On the right bottom corner of form `}<em parentName="li">{`Configure element`}</em>{`, click button `}<em parentName="li">{`Accept`}</em>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB50lEQVQ4y6WTy2sTURTG5w921Y0bQaGI4MZEpcYJoht3imgaaaotDMWGRKtkapJV666xsWYyzztzX5+cOzPJpKZ00QM/mPs433ndsR7UttDe3cOb9228/fAR79o7aHX2sE3sFnQ+o1Vh+xKtnU/o7Dt42nwJ6+j7D5BprXBTOzk5hdXr9cxCSAmtNZRSEFIgCCOc//UxOf8DP4zBuATLxApJJsCFBOfcaAyHQ1j9ft8sZCGoTbYaUZzgtxcjjBkiloFLIJN6FaGhiiTIxuPxGkGtzXeapohSAaVyh+p59Z5W6mrB0hhjSFMGnmWIosgQRxGUlMaZIEEKKou9hWCvEKRN6kUcxwYSTTLKatl0nU+vGOLyYG2GZWTKZjKZYDqdImGpOaNAF2dndOm/ssvSyUaj0XLK1ZJLE5xDSgEhBMLZDJrKK4ZwGfJ3XReW4zi5sxArUcl83zflX2dlyYPBAFb38NCok3MpWjZ90bs1ZVYpBUf0DrvlwxYCQRAY4fl8br4Dxq8VI0TRriH1cP/gi1nMPA9+EMDzPMx9H0EYIkgyk/1VqMUbzDN0fx7D6n79duN/+OCXj9fuDM7RMayNO/ew9eIVas9s1BvNJc+beLwOO+eJ3UStYeNRw8bth3Xcul/Hxt1N/APabmfjJES5WAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/5e9893bb4b777285289fa0c5332fa21d/e93cc/ec-configure-element-filled-in.webp 300w", "/static/5e9893bb4b777285289fa0c5332fa21d/b0544/ec-configure-element-filled-in.webp 600w", "/static/5e9893bb4b777285289fa0c5332fa21d/68fc1/ec-configure-element-filled-in.webp 1200w", "/static/5e9893bb4b777285289fa0c5332fa21d/b47ea/ec-configure-element-filled-in.webp 1692w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/5e9893bb4b777285289fa0c5332fa21d/eed55/ec-configure-element-filled-in.png 300w", "/static/5e9893bb4b777285289fa0c5332fa21d/7491f/ec-configure-element-filled-in.png 600w", "/static/5e9893bb4b777285289fa0c5332fa21d/8537d/ec-configure-element-filled-in.png 1200w", "/static/5e9893bb4b777285289fa0c5332fa21d/665a1/ec-configure-element-filled-in.png 1692w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/5e9893bb4b777285289fa0c5332fa21d/8537d/ec-configure-element-filled-in.png",
            "alt": "ec-configure-element-filled-in",
            "title": "ec-configure-element-filled-in",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
4. Configure placeholder (as explained in `}<a parentName="p" {...{
        "href": "/configure-placeholder.md"
      }}><em parentName="a">{`Configure placeholder`}</em></a>{`).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ddf06592262fb2ad60e6bedf4641adc5/dfd94/ec-onsite-survey.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABC0lEQVQoz53RW0vDMBQH8Hx9v4AfoW/Si2IrLdrLhjC8MMEHcVM3QdhWZ2/OrsnJX5JUwcuDeODHOUlOTh7CruY5JosCd3mt3fcenpueqWfKWql7DaarEvNVgWA8w242xU50DUZE+C2k4AAJ/CXe2g6bLeHsMQdrOw5JBCEE9HApwXmHoqzQbju9p84U3ucviEBS6sE3yxcw/u21hvDvuF1XYKPzS8RxgjhJcRInCI9jneMk+cwfkjTVhoMBMiXLEEUR/CBAGPg4OB2B+UchLMuC5zjavuv0tf2Dq9g29mzT4+p+F57n4VDdy4Zgk2WJp3KDkgOV6JFRK9JoFBglSb1+haHO1fddLFZ4B1t1Au/6MvpBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ddf06592262fb2ad60e6bedf4641adc5/e93cc/ec-onsite-survey.webp 300w", "/static/ddf06592262fb2ad60e6bedf4641adc5/b0544/ec-onsite-survey.webp 600w", "/static/ddf06592262fb2ad60e6bedf4641adc5/68fc1/ec-onsite-survey.webp 1200w", "/static/ddf06592262fb2ad60e6bedf4641adc5/a2303/ec-onsite-survey.webp 1800w", "/static/ddf06592262fb2ad60e6bedf4641adc5/4293a/ec-onsite-survey.webp 2400w", "/static/ddf06592262fb2ad60e6bedf4641adc5/9e988/ec-onsite-survey.webp 2806w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ddf06592262fb2ad60e6bedf4641adc5/eed55/ec-onsite-survey.png 300w", "/static/ddf06592262fb2ad60e6bedf4641adc5/7491f/ec-onsite-survey.png 600w", "/static/ddf06592262fb2ad60e6bedf4641adc5/8537d/ec-onsite-survey.png 1200w", "/static/ddf06592262fb2ad60e6bedf4641adc5/d2cc9/ec-onsite-survey.png 1800w", "/static/ddf06592262fb2ad60e6bedf4641adc5/00711/ec-onsite-survey.png 2400w", "/static/ddf06592262fb2ad60e6bedf4641adc5/dfd94/ec-onsite-survey.png 2806w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ddf06592262fb2ad60e6bedf4641adc5/8537d/ec-onsite-survey.png",
              "alt": "ec-onsite-survey",
              "title": "ec-onsite-survey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
5. Click `}<em parentName="p">{`Segmentation tab`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a90f50640ad3e3e9b7d07379d59d670d/3105b/ec-online-segmentation-tab.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAApklEQVQY05XOzQqCQAAE4H386I2kTl2CfqQfUDQFMc1Vl8q1tGx3J1IKhTw48MEwpyEzK4IRnmHRDHbMYScch4TDSXM4Ke9w2xiHHV/hJhdoRoTRxMR4aoLcHhX+RlSNgSE0KyCVQiXkz0tI8HsBXjwhpOrsNdmP5GX/i7wUUGrgQ80MsT4yLP0UK5/VXQ8YFh7F3IuhByk2J4btR8Sw+6IM+7a48QbV536c1wCU0gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a90f50640ad3e3e9b7d07379d59d670d/e93cc/ec-online-segmentation-tab.webp 300w", "/static/a90f50640ad3e3e9b7d07379d59d670d/b0544/ec-online-segmentation-tab.webp 600w", "/static/a90f50640ad3e3e9b7d07379d59d670d/68fc1/ec-online-segmentation-tab.webp 1200w", "/static/a90f50640ad3e3e9b7d07379d59d670d/a2303/ec-online-segmentation-tab.webp 1800w", "/static/a90f50640ad3e3e9b7d07379d59d670d/4293a/ec-online-segmentation-tab.webp 2400w", "/static/a90f50640ad3e3e9b7d07379d59d670d/8d25f/ec-online-segmentation-tab.webp 2792w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a90f50640ad3e3e9b7d07379d59d670d/eed55/ec-online-segmentation-tab.png 300w", "/static/a90f50640ad3e3e9b7d07379d59d670d/7491f/ec-online-segmentation-tab.png 600w", "/static/a90f50640ad3e3e9b7d07379d59d670d/8537d/ec-online-segmentation-tab.png 1200w", "/static/a90f50640ad3e3e9b7d07379d59d670d/d2cc9/ec-online-segmentation-tab.png 1800w", "/static/a90f50640ad3e3e9b7d07379d59d670d/00711/ec-online-segmentation-tab.png 2400w", "/static/a90f50640ad3e3e9b7d07379d59d670d/3105b/ec-online-segmentation-tab.png 2792w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a90f50640ad3e3e9b7d07379d59d670d/8537d/ec-online-segmentation-tab.png",
              "alt": "ec-online-segmentation-tab",
              "title": "ec-online-segmentation-tab",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
6. Activate the segmentation.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bf0bffb5421ca65fb24e297a5484652c/bcc05/ec-segmentation-activated.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA0ElEQVQY043My07DMBCFYb83Wx6Eh0BCiE2hBbEGxIKmIARtaidRCsYmN8+Pklilm6o90qdjj0ajLh4/uXpeMnnRTOaa67nmJtFMFybSzKLb1393b4ZpkjJLVpzdLzg5f+D08gnF3oTocNpOKF2DrVpUEGEQIhG6ENh8W6q6QeI/hDD0SHZaANkeV00Qmm7UBqGO/VN1GFtT7cyPifr1HmstznuccwPvHEX5RbGx21mv393He09T16iPteF9mZJmBSuTk/aynHUvH9/HyTBlyR9gQ8sH6eKJ3gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/bf0bffb5421ca65fb24e297a5484652c/e93cc/ec-segmentation-activated.webp 300w", "/static/bf0bffb5421ca65fb24e297a5484652c/b0544/ec-segmentation-activated.webp 600w", "/static/bf0bffb5421ca65fb24e297a5484652c/68fc1/ec-segmentation-activated.webp 1200w", "/static/bf0bffb5421ca65fb24e297a5484652c/a2303/ec-segmentation-activated.webp 1800w", "/static/bf0bffb5421ca65fb24e297a5484652c/4293a/ec-segmentation-activated.webp 2400w", "/static/bf0bffb5421ca65fb24e297a5484652c/6d63e/ec-segmentation-activated.webp 2780w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/bf0bffb5421ca65fb24e297a5484652c/eed55/ec-segmentation-activated.png 300w", "/static/bf0bffb5421ca65fb24e297a5484652c/7491f/ec-segmentation-activated.png 600w", "/static/bf0bffb5421ca65fb24e297a5484652c/8537d/ec-segmentation-activated.png 1200w", "/static/bf0bffb5421ca65fb24e297a5484652c/d2cc9/ec-segmentation-activated.png 1800w", "/static/bf0bffb5421ca65fb24e297a5484652c/00711/ec-segmentation-activated.png 2400w", "/static/bf0bffb5421ca65fb24e297a5484652c/bcc05/ec-segmentation-activated.png 2780w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/bf0bffb5421ca65fb24e297a5484652c/8537d/ec-segmentation-activated.png",
              "alt": "ec-segmentation-activated",
              "title": "ec-segmentation-activated",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
7. Click `}<em parentName="p">{`Add condition`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fe96b74931d98bfae2968e71e3f62af7/87dc2/ec-segmentation-add-condition.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA8ElEQVQoz42NWW7DIBRF2f8C+tGV9L/qFvrVSImd1E4cDMQ2M9wK4jHqdKWjo3eBBzk0Avtrh6IdMmXbo2QDjlzimLyGL5z4gILeUNIbXt4rPL1+4PltB4LvEgOCs9n/yWA8aG9AB3NfGGJEHElRWkN0/dxNhId5/WYKcT7AuADttk4/cmlh/b1L/inTyrSbxBjgnF/wHsZY0E5i0DbPdjwLwcP7LSGEbKVUNrFaQSkNY8yMVCrbOQtrF9Z3Zsa+ZQxSSpDruUZdVRCcLzAGwdm2+4ObEOi7DmRXnLCvGhzqkXOD4pFLg/I3muQLPmmLLydPaseIIYtqAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fe96b74931d98bfae2968e71e3f62af7/e93cc/ec-segmentation-add-condition.webp 300w", "/static/fe96b74931d98bfae2968e71e3f62af7/b0544/ec-segmentation-add-condition.webp 600w", "/static/fe96b74931d98bfae2968e71e3f62af7/68fc1/ec-segmentation-add-condition.webp 1200w", "/static/fe96b74931d98bfae2968e71e3f62af7/a2303/ec-segmentation-add-condition.webp 1800w", "/static/fe96b74931d98bfae2968e71e3f62af7/4293a/ec-segmentation-add-condition.webp 2400w", "/static/fe96b74931d98bfae2968e71e3f62af7/7ba4e/ec-segmentation-add-condition.webp 2796w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fe96b74931d98bfae2968e71e3f62af7/eed55/ec-segmentation-add-condition.png 300w", "/static/fe96b74931d98bfae2968e71e3f62af7/7491f/ec-segmentation-add-condition.png 600w", "/static/fe96b74931d98bfae2968e71e3f62af7/8537d/ec-segmentation-add-condition.png 1200w", "/static/fe96b74931d98bfae2968e71e3f62af7/d2cc9/ec-segmentation-add-condition.png 1800w", "/static/fe96b74931d98bfae2968e71e3f62af7/00711/ec-segmentation-add-condition.png 2400w", "/static/fe96b74931d98bfae2968e71e3f62af7/87dc2/ec-segmentation-add-condition.png 2796w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/fe96b74931d98bfae2968e71e3f62af7/8537d/ec-segmentation-add-condition.png",
              "alt": "ec-segmentation-add-condition",
              "title": "ec-segmentation-add-condition",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
8. Define the conditions.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6ff2d5dd1724c88c0fae8d102ab190c3/3cc45/ec-example-segmentation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAk0lEQVQY05XLXQrCMBAE4Nz/XIJCTyAWREFBm59NupttNmkjbRGx4IMfwzAvo5qLrrUOeZQySZnbYOqHnMcplbH+xrmo5vyoRVJK8g4RYWSRefMibUkfAiCp/ekGRjsAZo4LRPTeE1GMERbxGzMbrQ14dWjvYDrnPmciCiGsZ+/9urcQoUe1O15t99TG2H84azuAF962HbxiSHNIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6ff2d5dd1724c88c0fae8d102ab190c3/e93cc/ec-example-segmentation.webp 300w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/b0544/ec-example-segmentation.webp 600w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/68fc1/ec-example-segmentation.webp 1200w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/a2303/ec-example-segmentation.webp 1800w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/4293a/ec-example-segmentation.webp 2400w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/b41d4/ec-example-segmentation.webp 2850w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6ff2d5dd1724c88c0fae8d102ab190c3/eed55/ec-example-segmentation.png 300w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/7491f/ec-example-segmentation.png 600w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/8537d/ec-example-segmentation.png 1200w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/d2cc9/ec-example-segmentation.png 1800w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/00711/ec-example-segmentation.png 2400w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/3cc45/ec-example-segmentation.png 2850w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6ff2d5dd1724c88c0fae8d102ab190c3/8537d/ec-example-segmentation.png",
              "alt": "ec-example-segmentation",
              "title": "ec-example-segmentation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">{`The definition of segmentation rules is not mandatory, but it is highly recommended to prevent the widget from appearing on unwanted pages. There are various rules available, such as URL pages or localization. They can be general ("Segmentation_" tab or in the widget by clicking the spanner).`}</li>
      </ul>
      <ol parentName="blockquote" {...{
        "start": 9
      }}>
        <li parentName="ol">{`Go to `}<em parentName="li">{`Active elements`}</em>{` tab and click in the spanner of the element(`}<span parentName="li" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "48px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/3a30f3c028c6092b3a9999d13833a3e3/68013/icon-spanner.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "95.83333333333334%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAABYlAAAWJQFJUiTwAAABQ0lEQVQ4y82Uv4qDQBDG81SpgvYGrQSx9yF8hUhKXyG1lTYpbBSbQBrfQASbVFHj33zHLKzsSS4xHFxuQJZ1Zn58szO7q9vtht98dV2ztSxL5HmO1Z8CKZgn0FpV1be96H8JFIM5qO97tG07/X9LoaiEgyjhcrmgaZr3FXJlXdehKArYtg1N0+D7PoZhmFQvAvJASiJzXRfr9RphGLI9KSbfopLFUq/XKwM4joPNZoPj8Yjz+YzD4cBUvlQoHjYFkEVRxICGYUCSJCiKwsBkYtefAjksSRJst1sG8TwPQRAgTdMJtqhkDovjGKqqQpZl7Pd75iPjDZmP1kMgBd7vd6bMsiyYpondbodxHNmo0JnOYYuAp9MJuq6z7lJHuW+uanGXqawsy1gQDfRPsJdA8apxZfO7+9bj8Oj+PoN9/vn6F8AvSc6Vh/h2Gt8AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/3a30f3c028c6092b3a9999d13833a3e3/8e1ad/icon-spanner.webp 48w"],
                  "sizes": "(max-width: 48px) 100vw, 48px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/3a30f3c028c6092b3a9999d13833a3e3/68013/icon-spanner.png 48w"],
                  "sizes": "(max-width: 48px) 100vw, 48px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/3a30f3c028c6092b3a9999d13833a3e3/68013/icon-spanner.png",
                  "alt": "icon-spanner",
                  "title": "icon-spanner",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`). `}</li>
      </ol>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/03ec58e3970564614a78f23df64c32bb/ea8fa/ec-spanner-segmentation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABEElEQVQoz6WRUUrDQBCG98YeRLxBXoQURMQkNLbVRHoE8QzamGglTeIm2Z2d/SVbTX3RBvzhg51d9mOYEecXV1gka0TLO8xXCeardCS+Tce7RbrG8gg3yT3EdvsGFyZYNg42BGMIsBZaK7AxmBrxnGXIG41NKdEqQtURWs37V2thrQVbC2PMJMTT5gXvLSGvWnSKsKsblLsKUkp89BrMDDvwJT+GKIpi7Ia0Rt/3DqUUpKLJolGY57nzDYK6rh1lWaJpGjSdniQZRkKGwWwhsixzQqJ9N9/iQVi3PYj+ntl+YXxYyvDxv8kk4D+84rHoIXzfRxzHCMMQURQ5hvPP+jeugxBBEMKbXeLk1MOZN8MndgOh7C1qdKQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/03ec58e3970564614a78f23df64c32bb/e93cc/ec-spanner-segmentation.webp 300w", "/static/03ec58e3970564614a78f23df64c32bb/b0544/ec-spanner-segmentation.webp 600w", "/static/03ec58e3970564614a78f23df64c32bb/68fc1/ec-spanner-segmentation.webp 1200w", "/static/03ec58e3970564614a78f23df64c32bb/cd6e9/ec-spanner-segmentation.webp 1654w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/03ec58e3970564614a78f23df64c32bb/eed55/ec-spanner-segmentation.png 300w", "/static/03ec58e3970564614a78f23df64c32bb/7491f/ec-spanner-segmentation.png 600w", "/static/03ec58e3970564614a78f23df64c32bb/8537d/ec-spanner-segmentation.png 1200w", "/static/03ec58e3970564614a78f23df64c32bb/ea8fa/ec-spanner-segmentation.png 1654w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/03ec58e3970564614a78f23df64c32bb/8537d/ec-spanner-segmentation.png",
              "alt": "ec-spanner-segmentation",
              "title": "ec-spanner-segmentation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
10. Click `}<em parentName="p">{`Segmentation`}</em>{` tab.
11. Activate the segmentation.
12. Click `}<em parentName="p">{`Add condition`}</em>{`.
13. Define the conditions.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3cffa5554846395a1218032d2f3d82f4/a7b61/ec-segmentation-edit-element.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB80lEQVQ4y6WRbYvTQBDH88X9EvpSED2RE/HlgS888Hp9yGmv1tr0AVuUliZ9TJrNbnY3zcNfZrflOL0DwYEfmZ3Z+c9M1nl+dg633cGnestQa97gqulaGpZa00Xjpv0odfczvnz9hrO37+F4gyHIlFIo8gMOhwPyI0VRINMaRZ7jX2wymcLxPA8HACzViESG0ZJhFqaoKnupqiqUVWXEH4OGIBuNRlaQjBJ5USLLiQJSSuy4NoJA9eBEVXU/Ph6P7wTLsrQXaKKyNF1llptGdCZO/mkysjAMMZvN7gT7/T72+z3m8zm22y12ux0YYyYWhSE45+ZMhUmSIIoipGkKIYT5Uo5qaKPBYGAF8zw3BZvNxnwpSQWcC+NTYSrIt0L0gEpJaK3A4tjU0cSj4fBhQSvGjZhUygjGjINxiUQoMCHBeAouNRbLNX5Mf6IsCgyt4HdT4Ps+FosF1us14jg2q1CcpqEG22iPOEmRpAqJkAYSFjIDlwpZltmVO50OtNYIgsCwWq3MvyJBEj6RMGbWi/9ij10YQukMvV4PTrfbxcL3Qa9NE5IoTWs4NvFPsZN/hHLLIMB2FaCQHEPPgzOdTPC/NlinuPwlcOuN4bx6c46b9i2u6k1cN937tCx1Q+sPXFxeN/Cx1sCzdx/w5OUFnr54jd8moMv7h/8vWwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3cffa5554846395a1218032d2f3d82f4/e93cc/ec-segmentation-edit-element.webp 300w", "/static/3cffa5554846395a1218032d2f3d82f4/b0544/ec-segmentation-edit-element.webp 600w", "/static/3cffa5554846395a1218032d2f3d82f4/68fc1/ec-segmentation-edit-element.webp 1200w", "/static/3cffa5554846395a1218032d2f3d82f4/ed808/ec-segmentation-edit-element.webp 1666w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3cffa5554846395a1218032d2f3d82f4/eed55/ec-segmentation-edit-element.png 300w", "/static/3cffa5554846395a1218032d2f3d82f4/7491f/ec-segmentation-edit-element.png 600w", "/static/3cffa5554846395a1218032d2f3d82f4/8537d/ec-segmentation-edit-element.png 1200w", "/static/3cffa5554846395a1218032d2f3d82f4/a7b61/ec-segmentation-edit-element.png 1666w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/3cffa5554846395a1218032d2f3d82f4/8537d/ec-segmentation-edit-element.png",
              "alt": "ec-segmentation-edit-element",
              "title": "ec-segmentation-edit-element",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
14. Click `}<em parentName="p">{`Accept`}</em>{`.
15. Click button `}<em parentName="p">{`Save campaign`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ddf06592262fb2ad60e6bedf4641adc5/dfd94/ec-onsite-survey.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABC0lEQVQoz53RW0vDMBQH8Hx9v4AfoW/Si2IrLdrLhjC8MMEHcVM3QdhWZ2/OrsnJX5JUwcuDeODHOUlOTh7CruY5JosCd3mt3fcenpueqWfKWql7DaarEvNVgWA8w242xU50DUZE+C2k4AAJ/CXe2g6bLeHsMQdrOw5JBCEE9HApwXmHoqzQbju9p84U3ucviEBS6sE3yxcw/u21hvDvuF1XYKPzS8RxgjhJcRInCI9jneMk+cwfkjTVhoMBMiXLEEUR/CBAGPg4OB2B+UchLMuC5zjavuv0tf2Dq9g29mzT4+p+F57n4VDdy4Zgk2WJp3KDkgOV6JFRK9JoFBglSb1+haHO1fddLFZ4B1t1Au/6MvpBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ddf06592262fb2ad60e6bedf4641adc5/e93cc/ec-onsite-survey.webp 300w", "/static/ddf06592262fb2ad60e6bedf4641adc5/b0544/ec-onsite-survey.webp 600w", "/static/ddf06592262fb2ad60e6bedf4641adc5/68fc1/ec-onsite-survey.webp 1200w", "/static/ddf06592262fb2ad60e6bedf4641adc5/a2303/ec-onsite-survey.webp 1800w", "/static/ddf06592262fb2ad60e6bedf4641adc5/4293a/ec-onsite-survey.webp 2400w", "/static/ddf06592262fb2ad60e6bedf4641adc5/9e988/ec-onsite-survey.webp 2806w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ddf06592262fb2ad60e6bedf4641adc5/eed55/ec-onsite-survey.png 300w", "/static/ddf06592262fb2ad60e6bedf4641adc5/7491f/ec-onsite-survey.png 600w", "/static/ddf06592262fb2ad60e6bedf4641adc5/8537d/ec-onsite-survey.png 1200w", "/static/ddf06592262fb2ad60e6bedf4641adc5/d2cc9/ec-onsite-survey.png 1800w", "/static/ddf06592262fb2ad60e6bedf4641adc5/00711/ec-onsite-survey.png 2400w", "/static/ddf06592262fb2ad60e6bedf4641adc5/dfd94/ec-onsite-survey.png 2806w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ddf06592262fb2ad60e6bedf4641adc5/8537d/ec-onsite-survey.png",
              "alt": "ec-onsite-survey",
              "title": "ec-onsite-survey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
16. Activate the campaign in `}<em parentName="p">{`Status`}</em>{` toggle.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a35668f8c4153a5fb798a7b501409149/dfd94/ec-onsite-activated.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABBElEQVQoz53R0UoCQRiG4b387qEb8KzW0Y1W25kUK7AOBA+CoLTMSHTXFS3dnZk3HDfFEoI+ePhg5of5Ybz7YczTKKU/mTvP8cZLsijMfxk4C3qTGYNxQtDpc9x65KjexcNaDsXqHIzm71g+lhmr3HLVe8fLtMYYg9baWT+QZxnxNOVzudq7y4veYwymWKozHOP93G9u+He6owSvfXtHJCWRVFxEkrAeuZZSbc+/SaVQStFsNrlsNJzzMKQaBNTOAvzrNl5Yq1MqlaiKMpWyv+1DhL9xcrqeKSPcvKAiBGFF4Ldu8B5GqfvNSQZxIck3pnonNTtxbkiNZWZxpsaSAa3XN74A0v0C1o3klJsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a35668f8c4153a5fb798a7b501409149/e93cc/ec-onsite-activated.webp 300w", "/static/a35668f8c4153a5fb798a7b501409149/b0544/ec-onsite-activated.webp 600w", "/static/a35668f8c4153a5fb798a7b501409149/68fc1/ec-onsite-activated.webp 1200w", "/static/a35668f8c4153a5fb798a7b501409149/a2303/ec-onsite-activated.webp 1800w", "/static/a35668f8c4153a5fb798a7b501409149/4293a/ec-onsite-activated.webp 2400w", "/static/a35668f8c4153a5fb798a7b501409149/9e988/ec-onsite-activated.webp 2806w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a35668f8c4153a5fb798a7b501409149/eed55/ec-onsite-activated.png 300w", "/static/a35668f8c4153a5fb798a7b501409149/7491f/ec-onsite-activated.png 600w", "/static/a35668f8c4153a5fb798a7b501409149/8537d/ec-onsite-activated.png 1200w", "/static/a35668f8c4153a5fb798a7b501409149/d2cc9/ec-onsite-activated.png 1800w", "/static/a35668f8c4153a5fb798a7b501409149/00711/ec-onsite-activated.png 2400w", "/static/a35668f8c4153a5fb798a7b501409149/dfd94/ec-onsite-activated.png 2806w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a35668f8c4153a5fb798a7b501409149/8537d/ec-onsite-activated.png",
              "alt": "ec-onsite-activated",
              "title": "ec-onsite-activated",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`Notes:`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">{`By clicking on the eye, it is possible to preview the element.`}</li>
        <li parentName="ul">{`Make sure the element is activated by checking the element `}<em parentName="li">{`Status`}</em>{` toggle.`}</li>
        <li parentName="ul"><strong parentName="li">{`Segmentation`}</strong>{` can be added to the Campaign (going to `}<em parentName="li">{`Segmentation`}</em>{` tab) or to the Widget (Active Elements) (clicking in the spanner of the element).`}</li>
      </ul>
    </blockquote>
    <h4>{`Result`}</h4>
    <p>{`After activating the campaign, the campaign is available according to the rules defined in the segmentation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      